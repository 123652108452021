import React from 'react'
import Props from 'prop-types'
import MarkdownSerializer from 'markdown-it'
import qs from 'qs'

import Navigation from '../components/Navigation'
import {
  getContent,
  getLn,
  getQuestion,
  getSection,
  isWeb,
} from '../common/helpers'
import Container from '../components/Styled/Container'
import {
  PageContent,
  Section,
  SECTION_LAYOUT_WIDE,
} from '../components/Styled/Layout'
import Page from '../components/Page'
import GoBack from '../components/GoBack'
import { H2Mobile, MobileContent } from '../components/Styled/Typography'
import { languages } from '../common/constants'

const getFaqs = () => {
  // eslint-disable-next-line no-undef,require-unicode-regexp
  const context = require.context('../data/faqs', false, /\.*\.json$/)
  const collection = getContent(context)

  return collection
}

const FaqPage = ({ pageContext: { lang } }) => {
  const rawQueryParams = typeof location !== 'undefined' ? location.search : ''
  const ln = getLn(rawQueryParams)
  const language = languages.includes(ln) ? ln : 'en'
  const s = getSection(rawQueryParams) || 0
  const q = getQuestion(rawQueryParams) || 0
  const data = getFaqs()
  const answer = s && q && data[s].faq[q]

  const md = new MarkdownSerializer({
    html: true,
    linkify: true,
    typographer: true,
  })

  const questionTitle = answer[`question-${language}`]
    ? answer[`question-${language}`]
    : answer['question-en']
  const answerContent = answer[`answer-${language}`]
    ? answer[`answer-${language}`]
    : answer['answer-en']

  const qsParams = qs.parse(rawQueryParams, { ignoreQueryPrefix: true })
  // eslint-disable-next-line no-unused-vars
  const { s: section, q: question, ...qsParamsReduced } = qsParams
  const params = `?${qs.stringify(qsParamsReduced)}`

  return (
    <Page title={questionTitle} translates={lang}>
      <Navigation isMobileVisible={isWeb(rawQueryParams)} translates={lang} />
      <GoBack
        to={lang.slug !== 'en' ? `/${lang.slug}/qa/${params}` : `/qa/${params}`}
        isWeb={isWeb(rawQueryParams)}
        label={'Back to QA'}
      />
      <Container>
        <PageContent isMobileVisible={isWeb(rawQueryParams)}>
          <Section layout={SECTION_LAYOUT_WIDE}>
            <section>
              <H2Mobile>{questionTitle}</H2Mobile>
              {typeof answerContent === 'string' && (
                <MobileContent
                  dangerouslySetInnerHTML={{
                    __html: md.render(answerContent),
                  }}
                />
              )}
            </section>
          </Section>
        </PageContent>
      </Container>
    </Page>
  )
}

FaqPage.propTypes = {
  pageContext: Props.shape({
    faq: Props.any,
    lang: Props.any,
  }).isRequired,
}

export default FaqPage
