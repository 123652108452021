import React from 'react'
import Props from 'prop-types'
import { Link } from 'gatsby'
import { StyledGoBack } from './styled'

const GoBack = ({ to, label, isWeb = false }) => (
  <StyledGoBack isWeb={isWeb}>
    <Link to={to}>{label || 'Go Back'}</Link>
  </StyledGoBack>
)

GoBack.propTypes = {
  isWeb: Props.bool,
  label: Props.string.isRequired,
  to: Props.string.isRequired,
}

GoBack.defaultProps = {
  isWeb: false,
}

export default GoBack
