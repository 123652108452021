import styled, { css } from 'styled-components'

export const StyledGoBack = styled.div`
  position: fixed;
  z-index: 1;
  left: 2rem;
  top: 1.6rem;
  padding: 0.8rem 1rem 0.8rem 0;

  ${props =>
    props.isWeb &&
    css`
      left: 12rem;
      top: 3.4rem;
    `}

  a {
    text-decoration: none;
    padding-left: 1.5rem;
    color: #b07bdc;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 9px;
      height: 2px;
      background: #b07bdc;
      top: 50%;
      margin-top: -1px;
    }

    &:before {
      transform: rotate(45deg);
      transform-origin: left;
    }

    &:after {
      transform: rotate(-45deg);
      transform-origin: left;
    }
  }
`
